import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProductsIndexPage = () => (
  <Layout sectionClasses={"t-store-info"} dataNamespace={"store-info"}>
    <SEO title="STORE-URAKUCHO" />
    <div className="l-completed t-store-info">
      <div className="stores">
        <section className="stores__cat">
          <h2>SHOP</h2>
        </section>
      </div>

      <section className="container">
        <h2 className="name">
          HOTARU PERSONALIZED
          <br className="hide-pc" /> 有楽町マルイ店
        </h2>
        <div className="store-close">
          <p>お客様各位</p>
          <p>閉店のお知らせ</p>
          <p>勝手ながら、こちらの店舗は2022年7月28日をもって閉店致しました。</p>
          <p>
            長い間ご愛顧いただき誠にありがとうございます。
          </p>
        </div>
        <div className="fv">
          <img src={"/images/stores/yurakucho/fv.jpg"} alt="" className="" />
        </div>
        {/* <div className="txt">
          HOTARU PERSONALIZEDのフラグシップストア、「HOTARU PERSONALIZED SHOP」
          <p className="content-p">
            店頭では、HOTARU PERSONALIZEDの世界観を体験できます。
          </p>
          <p className="content-p">
            また専門スタッフによる肌診断で、よりお客様にぴったりのスキンケアをご提案いたします。
          </p>
        </div> */}

        {/* <div className="button">
          <a
            className="p-roundButton"
            href="https://coubic.com/sparty-studio/706242"
          >
            <span>店頭でスキンカウンセリングを受ける</span>
          </a>
        </div> */}

        {/* <h3 className="subtitle l-space__pt60">ABOUT SHOP</h3>
        <div className="store-address-info">
          <div className="store-address-info-box">
            <div className="store-address-info-label">会場</div>
            <div className="store-address-info-text">有楽町マルイ 1F</div>
          </div>
          <div className="store-address-info-box">
            <div className="store-address-info-label">住所</div>
            <div className="store-address-info-text">
              東京都千代田区有楽町2-7-1
            </div>
          </div>
          <div className="store-address-info-box">
            <div className="store-address-info-label">営業時間</div>
            <div className="store-address-info-text">
              11:00〜20:00　※館の営業時間に準ずる
            </div>
          </div>
          <div className="store-address-info-map">
            <iframe
              src={
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.1173501690005!2d139.76166161499643!3d35.67411238019597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188be5727dcea3%3A0xd60a27836288d3da!2z5pyJ5qW955S644Oe44Or44Kk!5e0!3m2!1sja!2sjp!4v1630481480337!5m2!1sja!2sjp"
              }
              width={"100%"}
              height={"300px"}
              frameborder={"0"}
              style={{ border: "0" }}
              allowfullscreen=""
              aria-hidden={"false"}
              tabindex={"0"}
            />
          </div>
        </div> */}

        {/* <h3 className="subtitle l-space__pt60">ABOUT SHOP</h3>
        <div className="content">
          <h4 className="content-title-sub">1. カウンセリング診断</h4>
          <p className="content-p">
            専門スタッフがスキンケアとインナーケアの両面からカウンセリングし、お客様にぴったりの商品をご提案いたします。
          </p>
          <h5>①肌質診断</h5>
          <p className="content-p">
            お客様の肌質を診断し、よりお客様にぴったりのHOTARU
            PERSONALIZEDをご提案いたします。
          </p>
          <h5>②肌タイプ別パワーフードアドバイス</h5>
          <p className="content-p">
            肌悩みに合わせた食事カウンセリングを実施いたします。外部だけでなく、内部からのアプローチで健康的できれいなお肌をサポートします。
          </p>
          <img
            src={"/images/stores/yurakucho/shop1.jpg?v=20220114"}
            alt=""
            className=""
          />
          <h4 className="content-title-sub">2. テクスチャー体験</h4>
          <p className="content-p">
            店頭限定で、テクスチャーをその場で体験いただけます。
          </p>
          <p className="content-p">
            HOTARU
            PERSONALIZEDの特徴である、オーダーメイドはテクスチャーもご自身のお好みに合わせることができます。
          </p>
          <p className="content-p">
            肌質に合う成分 ×
            お好みのテクスチャーを選んで、より快適なスキンケアライフにしませんか。
          </p>
          <img
            src={"/images/stores/yurakucho/shop2.jpg?v=20220114"}
            alt=""
            className=""
          />
          <h4 className="content-title-sub">3. 感染防止対策について</h4>
          <p className="content-p">
            営業にあたり、有楽町マルイでは以下の感染防止対策をしております。
            <br />
            <a href="https://www.0101.co.jp/torikumi/">
              大切なお客様へ【安心してお買い物いただくための感染防止の取り組み】
            </a>
          </p>
          <p className="content-p l-space__pt30 l-space__pb30">
            また、HOTARU PERSONALIZEDの店舗では
            <br />
            「マスク/手袋の着用」「手指の消毒」「接客に使用する機器の定期的な消毒」を行なっております。
            <br />
            お客様のご理解とご協力のほど、よろしくお願い申し上げます。
          </p>
        </div> */}

        {/* <div className="button">
          <a
            className="p-roundButton"
            href="https://coubic.com/sparty-studio/706242"
          >
            <span>店頭でスキンカウンセリングを受ける</span>
          </a>
        </div> */}
      </section>
      <section className="l-space__pb60 l-space__x30_sp">
        <div className="l-completed__backtotop">
          <a href="/" className="m-arwTxtLink type-left js-bubbleHover">
            BACK TO TOP
          </a>
        </div>
      </section>
    </div>
  </Layout>
)

export default ProductsIndexPage
